import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import { StaticQuery } from 'gatsby'
import TopCSS from '../menu/top.module.css'

const Top = () => (
  <StaticQuery
    query={graphql`
      query MenuQuery {
        imageMail: file(relativePath: { eq: "menu/paperplane.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        imageFb: file(relativePath: { eq: "menu/hand.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        imageInsta: file(relativePath: { eq: "menu/heart.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={({ imageMail, imageFb, imageInsta }) => (
      <section className={TopCSS.menu}>
        <div className={`${TopCSS.barbefore} ${TopCSS.bar}`} />
        <header className={`${TopCSS.remca} remca`}>
          <Link to="/">Rémi Carayon</Link>
        </header>
        <nav className={TopCSS.social}>
          <Link
            activeClassName="mail-active"
            className={`${TopCSS.iconbox} logichome Contact`}
            alt="Contact"
            to="/contact/"
          >
            <div className={`${TopCSS.mail} ${TopCSS.icon} menuiconmail`} />
            <Img
              className={`${TopCSS.image} imagehome`}
              style={{
                position: 'fixed',
              }}
              imgStyle={{ objectFit: 'contain', objectPosition: 'top center' }}
              title="Green Paper Plane"
              alt="Contact Image"
              fluid={imageMail.childImageSharp.fluid}
            />
          </Link>
          <a
            className={`${TopCSS.iconbox} logichome Facebook`}
            target="_blank"
            rel="noopener noreferrer"
            alt="Facebook Page"
            href="https://www.facebook.com/remcaphoto/"
          >
            {' '}
            <div className={`${TopCSS.fb} ${TopCSS.icon} menuiconfb`} />
            <Img
              className={`${TopCSS.image} imagehome`}
              style={{
                position: 'fixed',
              }}
              imgStyle={{ objectFit: 'contain', objectPosition: 'top center' }}
              title="Thumb Up"
              alt="Facebook Image"
              fluid={imageFb.childImageSharp.fluid}
            />
          </a>
          <a
            className={`${TopCSS.iconbox} logichome Instagram`}
            target="_blank"
            rel="noopener noreferrer"
            alt="Instagram Profil"
            href="https://instagram.com/remcaphoto/"
          >
            {' '}
            <div className={`${TopCSS.insta} ${TopCSS.icon} menuiconinsta`} />
            <Img
              className={`${TopCSS.image} imagehome`}
              style={{
                position: 'fixed',
              }}
              imgStyle={{ objectFit: 'contain', objectPosition: 'top center' }}
              title="Heart shaped like"
              alt="Instagram Image"
              fluid={imageInsta.childImageSharp.fluid}
            />
          </a>
          <div className={`${TopCSS.barafter} ${TopCSS.bar}`} />
        </nav>
      </section>
    )}
  />
)

export default Top
